import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import _ from "lodash"
import * as common from "../common"
import SEO from "../components/seo"

export default ({ data }) => {
  const team = _.find(data.allMysqlTeams.edges, function (edge) {
    return edge.node.longName === edge.node.mysqlId
  }).node
  const altNames = _.map(data.allMysqlTeams.edges, "node.mysqlId")
  const title = team.longName
  const teamStats = common.getTeamStats(
    data.allMysqlCompSeasonDataset.edges,
    altNames,
    data.allMysqlCompetitions.edges
  )
  const chartStats = JSON.stringify(common.getTeamChartStats(teamStats))

  return (
    <Layout>
      <SEO title={title} />
      <Helmet
        script={[
          {
            type: "text/javascript",
            innerHTML: `
                if(document.getElementById('canvas0') == null){
                  window.onload = function() {
                    insertTeamMixedBarchart('canvas0', ${chartStats});
                };
                } else {
                  insertTeamMixedBarchart('canvas0', ${chartStats});
                }
                `,
          },
        ]}
      />
      <h1>{title}</h1>
      <p>Country: {team.country}</p>
      {teamStats.map((teamStat, key) => (
        <div key={key}>
          <h2>Average home attendance in {teamStat.compLabel} by season:</h2>
          <div className="subsection row justify-content-center">
            <div className="col-12 col-md-9">
              <table className="table table-dark">
                <thead>
                  <tr>
                    <th className="col-center" scope="col">
                      Season
                    </th>
                    <th className="col-left" scope="col" colSpan="2">
                      Team home average
                    </th>
                    <th className="col-center" scope="col">
                      {teamStat.compLabel} average
                    </th>
                    <th className="col-center" scope="col">
                      Team/competition
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {teamStat.seasonAverages.map((seasonStat, key2) => (
                    <tr key={key2}>
                      <th className="col-center" scope="row">
                        <Link
                          to={`/compseason/${teamStat.compId}/${seasonStat.season}`}
                        >
                          Season {common.seasonParamToString(seasonStat.season)}
                        </Link>
                      </th>
                      <td className="col-center">{seasonStat.teamAverage}</td>
                      <td
                        className="col-left"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Change from previous season"
                      >
                        <span
                          className={
                            seasonStat.teamAverageChange >= 100
                              ? "green"
                              : "red"
                          }
                        >
                          <small>
                            {seasonStat.teamAverageChange}{" "}
                            {seasonStat.teamAverageChange && "%"}
                          </small>
                        </span>
                      </td>
                      <td className="col-center">{seasonStat.compAverage}</td>
                      <td className="col-center">
                        <span
                          className={
                            seasonStat.percentage >= 100 ? "green" : "red"
                          }
                        >
                          {seasonStat.percentage} %
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
      <p>
        <canvas id="canvas0"></canvas>
      </p>
    </Layout>
  )
}
export const query = graphql`
  query($urlSlug: String, $competitions: String) {
    allMysqlTeams(filter: { urlSlug: { eq: $urlSlug } }) {
      edges {
        node {
          country
          mysqlId
          latitude
          longitude
          longName
          shortName
          urlSlug
          competitions
        }
      }
    }
    allMysqlCompSeasonDataset(filter: { competition: { eq: $competitions } }) {
      edges {
        node {
          gamedate
          team1
          team2
          attendance
          season
          competition
          country
          stage
          location
        }
      }
    }
    allMysqlCompetitions {
      edges {
        node {
          mysqlId
          label
          logo
          type
          short
          latitude
          longitude
          teams
        }
      }
    }
  }
`
